export * from './TRX/send-data-layer';
export * from '@oracle-cx-commerce/actions';

// TRX
export const _getAddress = () => import('./TRX/getAddress');
export const _getContactInvoiceInstallments = () => import('./TRX/getContactInvoiceInstallments');
export const _getHerbalife = () => import('./TRX/getHerbalife');
export const _getJostensDynamicImages = () => import('./TRX/getJostensDynamicImages');

// NorteSul
export const _getAlvaraStatus = () => import('./NORTESUL/get-alvara-status');
export const _getLimitCredit = () => import('./NORTESUL/get-limit-credit');
export const _getOrderInformation = () => import('./NORTESUL/get-order-information');
export const _getOrganizationAndReprocess = () => import('./NORTESUL/get-organization-and-reprocess');
export const _getAddressByCep = () => import('./NORTESUL/get-address-by-cep');
export const _getPriceListsForProducts = () => import('./NORTESUL/get-price-lists-for-products');
export const _getCnpj = () => import('./NORTESUL/get-cnpj');
export const _getRepresentatives = () => import('./NORTESUL/get-representatives');
export const _requestRecaptcha = () => import('./NORTESUL/request-recaptcha');
export const _sendAlvaraEmail = () => import('./NORTESUL/send-alvara-email');
export const _sendEmailWithAttachments = () => import('./NORTESUL/send-email-with-attachments');
export const _sendNewsletter = () => import('./NORTESUL/send-newsletter');
export const _getExternalPrices = () => import('./NORTESUL/get-external-prices');
export const _listInventories = () => import('./NORTESUL/listInventories');
export const _sendEmailContactUs = () => import('./NORTESUL/send-email-contact-us');
export * from './NORTESUL/clearSession';
export * from './NORTESUL/set-dec-med';

//Jc Distribuição
export const _getQuotaSku = () => import('./JCDISTRIBUICAO/getQuotaSku');
