export * from '@oracle-cx-commerce/endpoints';
export * from '@oracle-cx-commerce/oce-endpoints';

// TRX
export const _getAddress = () => import('./TRX/getAddress');
export const _getContactInvoiceInstallments = () => import('./TRX/getContactInvoiceInstallments');
export const _getHerbalife = () => import('./TRX/getHerbalife');
export const _getJostensDynamicImages = () => import('./TRX/getJostensDynamicImages');

// NorteSul
export const _getAlvaraStatus = () => import('./NORTESUL/getAlvaraStatus');
export const _getLimitCredit = () => import('./NORTESUL/getLimitCredit');
export const _getOrderInformation = () => import('./NORTESUL/getOrderInformation');
export const _getOrganizationAndReprocess = () => import('./NORTESUL/getOrganizationAndReprocess');
export const _getAddressByCep = () => import('./NORTESUL/getAddressByCep');
export const _getPriceListsForProducts = () => import('./NORTESUL/getPriceListsForProducts');
export const _getCnpj = () => import('./NORTESUL/getCnpj');
export const _getRepresentatives = () => import('./NORTESUL/getRepresentatives');
export const _requestRecaptcha = () => import('./NORTESUL/requestRecaptcha');
export const _sendAlvaraEmail = () => import('./NORTESUL/sendAlvaraEmail');
export const _sendEmailWithAttachments = () => import('./NORTESUL/sendEmailWithAttachments');
export const _sendNewsletter = () => import('./NORTESUL/sendNewsletter');
export const _getExternalPrices = () => import('./NORTESUL/getExternalPrices');
export const _listInventories = () => import('./NORTESUL/listInventories');
export const _sendEmailContactUs = () => import('./NORTESUL/sendEmailContactUs');

//Jc Distribuição
export const _getQuotaSku = () => import('./JCDISTRIBUICAO/getQuotaSku');
